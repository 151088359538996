import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useUpdateTiktokPostTagMutation } from '@store/tiktok';
import { useUpdateInstagramReelTagMutation } from '@store/instagram';
import { useUpdateYoutubeVideoTagMutation } from '@store/youtube';

type PropsType = {
  postId: string;
  tagsArr: string[];
  TiktokDataTags: string;
  InstagramDataTags: string;
  YoutubeDataTags: string;
};

export const DropDownBtn: React.FC<PropsType> = ({
  postId,
  tagsArr,
  TiktokDataTags,
  InstagramDataTags,
  YoutubeDataTags,
}) => {
  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  const [currentTag, setCurrentTag] = useState<string>('');
  const [updateTiktokTag] = useUpdateTiktokPostTagMutation();
  const [updateInstagramTag] = useUpdateInstagramReelTagMutation();
  const [updateYoutubeTag] = useUpdateYoutubeVideoTagMutation();

  const onChange = async (event: ComboBoxChangeEvent) => {
    const selectedTag = event.value as string;
    setCurrentTag(selectedTag);
    const values = { tags: selectedTag };

    if (isTiktok) {
      await updateTiktokTag({
        id: postId,
        data: values,
      });
    }
    if (isInstagram) {
      await updateInstagramTag({
        id: postId,
        data: values,
      });
    }
    if (isYoutube) {
      await updateYoutubeTag({
        id: postId,
        data: values,
      });
    }
    setCurrentTag(values.tags);
  };

  useEffect(() => {
    if (isTiktok) {
      setCurrentTag(TiktokDataTags);
    }
    if (isInstagram) {
      setCurrentTag(InstagramDataTags);
    }
    if (isYoutube) {
      setCurrentTag(YoutubeDataTags);
    }
  }, [
    TiktokDataTags,
    InstagramDataTags,
    YoutubeDataTags,
    isInstagram,
    isTiktok,
    isYoutube,
  ]);

  return (
    <div className="drop-down-btn">
      <ComboBox
        style={{ width: '100%' }}
        data={tagsArr}
        id="postTags"
        allowCustom={true}
        value={currentTag}
        onChange={onChange}
        defaultValue=""
      />
    </div>
  );
};
