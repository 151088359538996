export const TIKTOK_DAILY_STATS = 'tiktok_daily_stats';
export const TIKTOK_PERIOD_STATS = 'tiktok_period_stats';
export const TIKTOK_PERIOD_CHART = 'tiktok_period_chart';
export const TIKTOK_POST = 'tiktok_post';
export const TIKTOK_POSTS = 'tiktok_posts';
export const TIKTOK_POSTS_TAGS = 'tiktok_posts_tags';
export const TIKTOK_COMMENTS = 'tiktok_comments';
export const TIKTOK_COMMENTS_POST = 'tiktok_comments_post';
export const TIKTOK_CONNECT = 'tiktok_connect';

export const INSTAGRAM_DAILY_STATS = 'instagram_daily_stats';
export const INSTAGRAM_PERIOD_STATS = 'instagram_period_stats';
export const INSTAGRAM_PERIOD_CHART = 'instagram_period_chart';
export const INSTAGRAM_REEL = 'instagram_reel';
export const INSTAGRAM_REELS = 'instagram_reels';
export const INSTAGRAM_REELS_TAGS = 'instagram_reels_tags';
export const INSTAGRAM_COMMENTS = 'instagram_comments';
export const INSTAGRAM_CONNECT = 'instagram_connect';

export const YOUTUBE_DAILY_STATS = 'youtube_videos_stats_daily';
export const YOUTUBE_PERIOD_STATS = 'youtube_period_stats';
export const YOUTUBE_PERIOD_CHART = 'youtube_period_chart';
export const YOUTUBE_VIDEO = 'youtube_video';
export const YOUTUBE_VIDEOS = 'youtube_videos';
export const YOUTUBE_VIDEOS_TAGS = 'youtube_videos_tags';
export const YOUTUBE_COMMENTS = 'youtube_comments';
export const YOUTUBE_CONNECT = 'youtube_connect';

export const VIDEO_DAILY_STATS = 'video_daily_stats';
export const VIDEO_PERIOD_STATS = 'video_period_stats';
export const VIDEO_PERIOD_CHART = 'video_period_chart';
export const VIDEO_POST = 'video_post';
export const VIDEO_POSTS = 'video_posts';
export const VIDEO_POSTS_TAGS = 'video_posts_tags';
export const VIDEO_COMMENTS = 'video_comments';

export const USERS_COLLECTION = 'users';
export const AUTH = 'auth';

export const ASSISTENT_COMMENTS = 'assistant';

export const DOWNLOAD = 'download';

export const PARSERHISTORY_COLLECTION = 'parser_history';

export const CHANNEL_NAME = 'dasha_shpringer';
export const TIKTOK_LINK = 'https://www.tiktok.com/';
export const GOOGLE_STORAGE_API_VIDEO_LINK =
  'https://storage.googleapis.com/video-covers/';
