import { TIKTOK_LINK, CHANNEL_NAME } from '@core/config';
import { ECoefficient } from '@models/coefficient.enum';
// import { EPlatform } from '@models/platform.enum';
import { IDailyStats } from '@store/models/daily-stats.interface';

import {
  IVideoDailyStats,
  IVideoPost,
} from '@store/models/video-post.interfaces';

import moment from 'moment';
import numbro from 'numbro';

export interface IFormatData {
  // platform: EPlatform;
  posts: IVideoPost[];
  postsData: IVideoDailyStats[];
}

export const formatData = async ({
  // platform,
  posts,
  postsData,
}: IFormatData): Promise<IDailyStats[]> => {
  if (!posts.length && !postsData.length) return [];

  const stats: IDailyStats[] = await Promise.all(
    postsData.map(async post => {
      const postData = posts.find(el => el._id === post._id.toString());

      const postId = post._id.toString();
      let title = `${postData?.video.desc} -- ${postData?.platform}`;
      const tags = postData?.tags ?? null;
      const followers = Math.round(post.followers);

      const er =
        post.play_count > 0
          ? (
              ((post.digg_count +
                post.comment_count +
                post.share_count +
                post.followers) /
                post.play_count) *
              100
            ).toFixed(2) + '%'
          : '0%';
      const finish = post.finish_rate;
      const date = moment(post.recordCreated._i).format('D MMM, YYYY');
      const views = numbro(post.play_count).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      const likes = numbro(post.digg_count).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      const comments = Math.trunc(post.comment_count);
      const shares = Math.round(post.share_count);
      const totalHours = numbro(post.total_duration / (1000 * 60 * 60)).format({
        thousandSeparated: true,
        mantissa: 2,
      });

      const sec =
        postData?.video?.video?.duration || postData?.video?.duration || 0;

      let imageUrl = '';
      let fin = '';
      let videoUrl = '';
      let forecast = '';
      let totalViews = '0';
      let totalComments = '0';
      let totalLikes = '0';
      let totalShares = '0';
      let platform = postData?.platform;

      forecast = calculateForecast(sec, finish);

      if (platform === 'tiktok') {
        imageUrl = postData?.video?.video?.cover || '';
        fin = (post.finish_rate * 100).toFixed(2) + '%';
        videoUrl = `${TIKTOK_LINK}@${CHANNEL_NAME}/video/${postData?._id}`;
      } else if (platform === 'instagram') {
        imageUrl = postData?.video?.cover || '';
        fin = (post.finish_rate * 0.1).toFixed(2) + '%';
        videoUrl = postData?.video.url;
      } else if (platform === 'youtube') {
        imageUrl = postData?.video?.cover || '';
        fin = (post.finish_rate * 0.01).toFixed(2) + '%';
        videoUrl = postData?.video.url;
        forecast = calculateForecast(sec, finish * 0.01);
      }

      totalViews = numbro(postData?.video?.stats?.playCount ?? 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      totalLikes = numbro(postData?.video?.stats?.diggCount ?? 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      totalComments = numbro(postData?.video?.stats?.commentCount ?? 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      totalShares = numbro(postData?.video?.stats?.shareCount ?? 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });

      // switch (platform) {
      // case EPlatform.TIKTOK:
      // console.log('TIKTOK');
      // fin = (post.finish_rate * 100).toFixed(2) + '%';
      // videoUrl = `${TIKTOK_LINK}@${CHANNEL_NAME}/video/${postData?._id}`;
      // forecast = calculateForecast(sec, finish);
      // break;

      // case EPlatform.INSTAGRAM:
      // console.log('INSTAGRAM');
      // videoUrl = postData?.video.url;
      // fin = (post.finish_rate * 0.1).toFixed(2) + '%';
      // forecast = calculateForecast(sec, finish);
      // break;

      // case EPlatform.YOUTUBE:
      // console.log('YOUTUBE');
      // fin = (post.finish_rate * 0.01).toFixed(2) + '%';
      // videoUrl = postData?.video.url;
      // forecast = calculateForecast(sec, finish * 0.01);
      // break;

      // case EPlatform.ALL:
      //   console.log('all');
      //   break;

      // default:
      // }

      return {
        imageUrl,
        title,
        videoUrl,
        er,
        forecast,
        tags,
        sec,
        fin,
        date,
        followers,
        views,
        likes,
        comments,
        shares,
        totalViews,
        totalLikes,
        totalComments,
        totalShares,
        totalHours,
        postId,
        platform,
      };
    }),
  );

  return stats;
};

const calculateForecast = (duration: number, finish: number): string => {
  const min = numbro(
    Math.max(
      0,
      finish * duration * ECoefficient.COEFFICIENT1 - ECoefficient.COEFFICIENT2,
    ),
  ).format({ average: true });
  const max = numbro(
    finish * duration * ECoefficient.COEFFICIENT3 + ECoefficient.COEFFICIENT4,
  ).format({ average: true });
  return `${min} - ${max}`;
};
