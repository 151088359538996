import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useMatch, useOutletContext } from 'react-router-dom';
import { IVideoDailyStats, IVideoPost } from '@store/models';
import {
  useGetTiktokDailyStatsQuery,
  useGetTiktokPostsArrByIdQuery,
} from '@store/tiktok';
import {
  useGetInstagramDailyStatsQuery,
  useGetInstagramReelsArrByIdQuery,
} from '@store/instagram';
import {
  useGetYoutubeDailyStatsQuery,
  useGetYoutubeVideosArrByIdQuery,
} from '@store/youtube';

import { Popup } from '@progress/kendo-react-popup';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  InstagramEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';
import { Spinner } from '@components/ui-kit';
import {
  TagsCell,
  ImgCell,
  TitleCell,
  TotalCommentsCell,
  CommentsCell,
} from './models';

import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { IDailyStats } from '@store/models/daily-stats.interface';
import { EPlatform } from '@models/platform.enum';
import { formatData } from './format-data';

import './styles.scss';
import {
  useGetVideoDailyStatsQuery,
  useGetVideoPostsArrByIdQuery,
} from '@store/video';

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = {
  take: 10,
  skip: 0,
};

const initialSort: Array<SortDescriptor> = [
  { field: 'followers', dir: 'desc' },
];

const PopUp: React.FC<any> = ({ show, anchor, currentLink }) => {
  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  return (
    <Popup show={show} anchor={anchor.current}>
      {isTiktok && <TikTokEmbed url={currentLink} />}
      {isInstagram && <InstagramEmbed url={currentLink} />}
      {isYoutube && <YouTubeEmbed url={currentLink} />}
    </Popup>
  );
};

type PropsType = {
  startDate: string;
  endDate: string;
  searchParams: any;
  setSearchParams: any;
  post_id: any;
  setPost_id: any;
};

export const InformTable: React.FC = () => {
  const {
    startDate,
    endDate,
    searchParams,
    post_id,
    setPost_id,
    setSearchParams,
  } = useOutletContext() as PropsType;

  const [page, setPage] = useState<PageState>(initialDataState);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [countCollection, setCountCollection] = useState<number>(0);
  const [dailyStats, setDailyStats] = useState<IDailyStats[]>([]);
  const [DailyStatsLoading, setDailyStatsLoading] = useState<boolean>(true);
  const [TiktokData, setTiktokData] = useState<IVideoPost[]>([]);
  const [InstagramData, setInstagramData] = useState<IVideoPost[]>([]);
  const [YoutubeData, setYoutubeData] = useState<IVideoPost[]>([]);
  // const [VideoData, setVideoData] = useState<IVideoPost[]>([]);

  const [TiktokPostsIdArr, setTiktokPostsIdArr] = useState<string>();
  const [InstagramPostsIdArr, setInstagramPostsIdArr] = useState<string>();
  const [YoutubePostsIdArr, setYoutubePostsIdArr] = useState<string>();
  // const [VideoPostsIdArr, setVideoPostsIdArr] = useState<string>();

  const [sort, setSort] = useState(initialSort);
  const [sortParam, setSortParam] = useState<string>('followers');
  const [sortOrderParam, setSorOrderParam] = useState<any>('');

  const [show, setShow] = useState(false);
  const [currentLink, setCurrentLink] = useState<string>('');
  const anchor = useRef(null);

  const pageQuery = searchParams.get('page') || currentPage;
  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');
  // const isAllPlatform = useMatch('/all');

  useEffect(() => {
    if (sort) {
      setSortParam(sort[0]?.field || 'followers');
      setSorOrderParam(sort[0]?.dir || 'desc');
    }
  }, [sort]);

  const { data: DailyStatsDataTiktok, isLoading: DailyStatsLoadingTiktok } =
    useGetTiktokDailyStatsQuery(
      startDate &&
        endDate &&
        sort && {
          start: startDate,
          end: endDate,
          sort: sortParam,
          ord: sortOrderParam,
          page: pageQuery,
          post_id: post_id,
        },
    );
  const {
    data: DailyStatsDataInstagram,
    isLoading: DailyStatsLoadingInstagram,
  } = useGetInstagramDailyStatsQuery(
    startDate &&
      endDate &&
      sort && {
        start: startDate,
        end: endDate,
        sort: sortParam,
        ord: sortOrderParam,
        page: pageQuery,
        post_id: post_id,
      },
  );

  const { data: DailyStatsDataYoutube, isLoading: DailyStatsLoadingYoutube } =
    useGetYoutubeDailyStatsQuery(
      startDate &&
        endDate &&
        sort && {
          start: startDate,
          end: endDate,
          sort: sortParam,
          ord: sortOrderParam,
          page: pageQuery,
          post_id: post_id,
        },
    );

  // const { data: DailyStatsDataVideo, isLoading: DailyStatsLoadingVideo } =
  //   useGetVideoDailyStatsQuery(
  //     startDate &&
  //       endDate &&
  //       sort && {
  //         start: startDate,
  //         end: endDate,
  //         sort: sortParam,
  //         ord: sortOrderParam,
  //         page: pageQuery,
  //         post_id: post_id,
  //       },
  //   );

  const { data: tiktokArr = [], isLoading: tiktokPostLoading } =
    useGetTiktokPostsArrByIdQuery(TiktokPostsIdArr || null);

  const { data: instagramArr = [], isLoading: instagramReelsLoading } =
    useGetInstagramReelsArrByIdQuery(InstagramPostsIdArr || null);

  const { data: youtubeArr = [], isLoading: youtubeVideosLoading } =
    useGetYoutubeVideosArrByIdQuery(YoutubePostsIdArr || null);

  // const { data: videoArr = [], isLoading: videosLoading } =
  //   useGetVideoPostsArrByIdQuery(VideoPostsIdArr || null);

  useEffect(() => {
    const newSearchParams = new URLSearchParams({
      start: startDate,
      end: endDate,
      sort: sortParam,
      ord: sortOrderParam,
      page: `${currentPage}`,
      post_id: post_id,
    });
    setSearchParams(newSearchParams);
  }, [
    startDate,
    endDate,
    sort,
    currentPage,
    setSearchParams,
    sortParam,
    sortOrderParam,
    post_id,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (isTiktok && DailyStatsDataTiktok && !DailyStatsLoadingTiktok) {
        setTiktokData(tiktokArr);
        const formattedData = await formatData({
          // platform: EPlatform.TIKTOK,
          posts: tiktokArr,
          postsData: DailyStatsDataTiktok.collection,
        });
        setDailyStats(formattedData);
        setCountCollection(DailyStatsDataTiktok.countCollection);
      }
      if (
        isInstagram &&
        DailyStatsDataInstagram &&
        !DailyStatsLoadingInstagram
      ) {
        setInstagramData(instagramArr);
        const formattedData = await formatData({
          // platform: EPlatform.INSTAGRAM,
          posts: instagramArr,
          postsData: DailyStatsDataInstagram.collection,
        });
        setDailyStats(formattedData);
        setCountCollection(DailyStatsDataInstagram.countCollection);
      }
      if (isYoutube && DailyStatsDataYoutube && !DailyStatsLoadingYoutube) {
        setYoutubeData(youtubeArr);
        const formattedData = await formatData({
          // platform: EPlatform.YOUTUBE,
          posts: youtubeArr,
          postsData: DailyStatsDataYoutube.collection,
        });
        setDailyStats(formattedData);
        setCountCollection(DailyStatsDataYoutube.countCollection);
      }
      // if (isAllPlatform && DailyStatsDataVideo && !DailyStatsLoadingVideo) {
      //   setVideoData(videoArr);
      //   // setCountCollection(DailyStatsDataVideo.countCollection);
      //   // setAllPlatformData(allPlatformArr);

      //   const formattedData = await formatData({
      //     platform: EPlatform.ALL,
      //     posts: videoArr,
      //     postsData: DailyStatsDataVideo.collection,
      //   });

      //   setDailyStats(formattedData);
      //   setCountCollection(DailyStatsDataVideo.countCollection);
      // }
    };

    fetchData();
  }, [
    DailyStatsDataInstagram,
    DailyStatsDataTiktok,
    DailyStatsDataYoutube,
    DailyStatsLoadingInstagram,
    DailyStatsLoadingTiktok,
    DailyStatsLoadingYoutube,
    isInstagram,
    isTiktok,
    isYoutube,
    tiktokArr,
    instagramArr,
    youtubeArr,
    // isAllPlatform,
  ]);

  // Memoized data for performance optimization
  const memoizedDailyStats = useMemo(() => dailyStats, [dailyStats]);

  useEffect(() => {
    if (DailyStatsDataTiktok?.collection && !DailyStatsLoadingTiktok) {
      const filteredCollection: string[] = [];
      DailyStatsDataTiktok.collection.map((item: IVideoDailyStats) => {
        return filteredCollection.push(item.postId);
      });
      setTiktokPostsIdArr(filteredCollection.toString());
    }
  }, [DailyStatsDataTiktok, DailyStatsLoadingTiktok]);

  useEffect(() => {
    if (DailyStatsDataInstagram?.collection && !DailyStatsLoadingInstagram) {
      const filteredCollection: string[] = [];
      DailyStatsDataInstagram.collection.map((item: IVideoDailyStats) => {
        return filteredCollection.push(item.postId);
      });
      setInstagramPostsIdArr(filteredCollection.toString());
    }
  }, [DailyStatsDataInstagram, DailyStatsLoadingInstagram]);

  useEffect(() => {
    if (DailyStatsDataYoutube?.collection && !DailyStatsLoadingYoutube) {
      const filteredCollection: string[] = [];
      DailyStatsDataYoutube.collection.map((item: IVideoDailyStats) => {
        return filteredCollection.push(item.postId);
      });
      setYoutubePostsIdArr(filteredCollection.toString());
    }
  }, [DailyStatsDataYoutube, DailyStatsLoadingYoutube]);

  // useEffect(() => {
  //   if (DailyStatsDataVideo?.collection && !DailyStatsLoadingVideo) {
  //     const filteredCollection: string[] = [];
  //     DailyStatsDataVideo.collection.map((item: IVideoDailyStats) => {
  //       return filteredCollection.push(item.postId);
  //     });
  //     setVideoPostsIdArr(filteredCollection.toString());
  //   }
  // }, [DailyStatsDataVideo, DailyStatsLoadingVideo]);

  useEffect(() => {
    if (isTiktok && !DailyStatsLoadingTiktok && !tiktokPostLoading) {
      setDailyStatsLoading(false);
    }
    if (isInstagram && !DailyStatsLoadingInstagram && !instagramReelsLoading) {
      setDailyStatsLoading(false);
    }
    if (isYoutube && !DailyStatsLoadingYoutube && !youtubeVideosLoading) {
      setDailyStatsLoading(false);
    }
    // if (isAllPlatform && !DailyStatsLoadingVideo && !videosLoading) {
    //   setDailyStatsLoading(false);
    // }
  }, [
    DailyStatsLoadingInstagram,
    DailyStatsLoadingTiktok,
    DailyStatsLoadingYoutube,
    tiktokPostLoading,
    instagramReelsLoading,
    youtubeVideosLoading,
    isTiktok,
    isInstagram,
    isYoutube,
    // isAllPlatform,
  ]);

  const PopupHandler = () => {
    setShow(!show);
  };

  const ImgCellProps = useCallback(
    (props: any) => (
      <ImgCell
        {...{
          ...props,
          currentLink: currentLink,
          setCurrentLink: setCurrentLink,
          onClick: PopupHandler,
        }}
      />
    ),
    [currentLink],
  );

  const TagsCellProps = useCallback(
    (props: any) => (
      <TagsCell
        {...{
          ...props,
          TiktokData: TiktokData,
          InstagramData: InstagramData,
          YoutubeData: YoutubeData,
          // VideoData: VideoData,
        }}
      />
    ),
    [TiktokData, InstagramData, YoutubeData],
  );

  const CommentsCellProps = useCallback(
    (props: any) => (
      <CommentsCell
        {...{
          ...props,
          TiktokData: TiktokData,
          InstagramData: InstagramData,
          YoutubeData: YoutubeData,
          // VideoData: VideoData,
          setPost_id: setPost_id,
        }}
      />
    ),
    [TiktokData, InstagramData, YoutubeData],
  );

  const TotalCommentsCellProps = useCallback(
    (props: any) => (
      <TotalCommentsCell
        {...{
          ...props,
          TiktokData: TiktokData,
          InstagramData: InstagramData,
          YoutubeData: YoutubeData,
          // VideoData: VideoData,
          setPost_id: setPost_id,
        }}
      />
    ),
    [TiktokData, InstagramData, YoutubeData],
  );

  const pageChange = useCallback((event: GridPageChangeEvent) => {
    setPage(event.page);
  }, []);

  useEffect(() => {
    setCurrentPage(Number(page.skip) / 10 + 1);
  }, [page]);

  return (
    <>
      {DailyStatsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="anchor" ref={anchor}></div>
          <div className="inform-table">
            <PopUp
              show={show}
              PopupHandler={PopupHandler}
              anchor={anchor}
              currentLink={currentLink}
            />
            <Grid
              pageable={true}
              sortable={true}
              lockGroups={true}
              filterable={false}
              data={memoizedDailyStats}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              total={countCollection}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
            >
              <Column
                title="Image"
                width="80px"
                locked={true}
                cell={ImgCellProps}
              />
              <Column
                title="Title"
                width="350px"
                locked={true}
                cell={TitleCell}
              />
              <Column field="er" title="Er" width="80px" />
              <Column field="forecast" title="Forecast" width="120px" />
              <Column title="Tags" width="160px" cell={TagsCellProps} />
              <Column field="sec" title="sec" width="80px" />
              <Column field="fin" title="Fin" width="80px" />
              <Column field="date" title="Date" width="120px" />
              <Column field="followers" title="Followers" width="100px" />
              <Column field="views" title="Views" width="80px" />
              <Column field="likes" title="Likes" width="80px" />
              <Column
                field="comments"
                title="Сomments"
                width="100px"
                cell={CommentsCellProps}
              />
              <Column field="shares" title="Shares" width="80px" />
              <Column field="totalViews" title="∑Views" width="100px" />
              <Column field="totalLikes" title="∑Likes" width="100px" />
              <Column
                field="totalComments"
                title="∑Comments"
                width="100px"
                cell={TotalCommentsCellProps}
              />
              <Column field="totalShares" title="∑Shares" width="80px" />
              <Column field="totalHours" title="∑Hours" width="120px" />
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
