import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY, IVideoPost } from '../models';
import { VIDEO_POST, VIDEO_POSTS } from '@core/config';

export const videoPostsAPI = createApi({
  reducerPath: VIDEO_POSTS,
  tagTypes: ['Video_posts'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getVideoPosts: builder.query<IVideoPost[], any>({
      query: params => ({
        url: VIDEO_POSTS,
        params,
      }),
      providesTags: ['Video_posts'],
    }),

    getVideoPostsArrById: builder.query<IVideoPost[], any>({
      query(id) {
        return { url: `${VIDEO_POSTS}/${id}` };
      },
      providesTags: ['Video_posts'],
    }),

    getVideoPost: builder.query({
      query(id) {
        return { url: `${VIDEO_POST}/${id}` };
      },
      providesTags: ['Video_posts'],
    }),

    // getTiktokPostsTags: builder.query<string[], any>({
    //   query: params => ({
    //     url: TIKTOK_POSTS_TAGS,
    //     params,
    //   }),
    //   providesTags: ['Video_posts'],
    // }),

    // updateTiktokPostTag: builder.mutation<IVideoPost, any>({
    //   query({ id, data }) {
    //     return {
    //       url: `${TIKTOK_POSTS_TAGS}/${id}`,
    //       method: 'PATCH',
    //       body: data,
    //     };
    //   },
    //   invalidatesTags: ['Video_posts'],
    // }),
  }),
});

export const {
  useGetVideoPostQuery,
  useGetVideoPostsQuery,
  useGetVideoPostsArrByIdQuery,
} = videoPostsAPI;
