import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { Spinner } from '@components/ui-kit/spinner';
import { useGetInstagramReelTagsQuery } from '@store/instagram';
import { useGetYoutubeVideosTagsQuery } from '@store/youtube';
import { useGetTiktokPostsTagsQuery } from '@store/tiktok';
import { DropDownBtn } from '../drop-down-btn';
import { IVideoPost } from '@store/models';

export const TagsCell: React.FC<any> = ({ ...props }) => {
  const [tagsArr, setTagsArr] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let styles = props.style;
  let classNames = props.className;
  const postId = props.dataItem.postId;

  const TiktokData: IVideoPost = props.TiktokData.find(
    (post: IVideoPost) => post._id === postId,
  );
  const InstagramData: IVideoPost = props.InstagramData.find(
    (reel: IVideoPost) => reel._id === postId,
  );
  const YoutubeData: IVideoPost = props.YoutubeData?.find(
    (video: IVideoPost) => video._id === postId,
  );

  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  const { data: InstagramDataTags = [], isLoading: isInstagramLoading } =
    useGetInstagramReelTagsQuery(null);

  const { data: TiktokDataTags = [], isLoading: isTiktokLoading } =
    useGetTiktokPostsTagsQuery(null);

  const { data: YoutubeDataTags = [], isLoading: isYoutubeLoading } =
    useGetYoutubeVideosTagsQuery(null);

  useEffect(() => {
    if (isTiktok) {
      setTagsArr(TiktokDataTags);
      setLoading(isTiktokLoading);
    }
    if (isInstagram) {
      setTagsArr(InstagramDataTags);
      setLoading(isInstagramLoading);
    }
    if (isYoutube) {
      setTagsArr(YoutubeDataTags);
      setLoading(isYoutubeLoading);
    }
  }, [
    isTiktok,
    isInstagram,
    isYoutube,
    isTiktokLoading,
    isInstagramLoading,
    isYoutubeLoading,
    TiktokDataTags,
    InstagramDataTags,
    YoutubeDataTags,
  ]);

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      {loading ? (
        <Spinner />
      ) : (
        <DropDownBtn
          postId={postId}
          tagsArr={tagsArr}
          TiktokDataTags={TiktokData?.tags}
          InstagramDataTags={InstagramData?.tags}
          YoutubeDataTags={YoutubeData?.tags}
        />
      )}
    </td>
  );
};
